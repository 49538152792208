<template>
  <div class="contract-finish">
    <div class="contract-finish-content">
      <div
        class="cursor-pointer icon-display"
        :class="close.icon"
        v-if="close && close.callback"
        @click="close.callback"
      />
      <div class="contract-finish-card">
        <img
          class="diary-finish-image margin-bottom"
          width="56px"
          :src="successImg"
          :alt="$translations.contracts.title"
        />
        <div v-text="$translations.contracts.banner.title" class="margin-bottom heading" />
        <div v-text="$translations.contracts.banner.subtitle" class="body" />
      </div>
    </div>
  </div>
</template>

<script>
import { PNG } from "@seliaco/red-panda";

export default {
  name: 'SuccessSign',
  props: {
    close: {
      icon: String,
      callback: Function,
    },
  },
  data () {
    return {
      successImg: PNG.Success,
    }
  },
}
</script>

<style lang="sass" scoped>
.contract-finish
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100vh
  background: var(--gradient-two)
  width: 100%
  padding: 16px
  &-image
    margin: 0 auto
  &-content
    display: flex
    flex-direction: column
    max-width: 1040px
    width: 100%
    height: 100%
  &-card
    display: flex
    flex-direction: column
    align-items: center
    padding: 24px
    border-radius: 24px
    background: var(--white)
    max-width: 448px
    margin: auto
    text-align: center
    height: fit-content
  .icon-display
    align-self: flex-end
</style>
