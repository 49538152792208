<template>
  <div :class="{ 'not-signed': !alreadySigned }">
    <PageContainer
      v-if="!showBanner"
      :title="$translations.contracts.title"
      :back-enabled="false"
      :ellipsis="true"
      :submit="submit"
    >
      <template v-slot:content>
        <div class="page-segment">
          <div class="page-segment-content">
            <template v-if="contract">
              <HtmlViewer :html-content="contract.content" class="body margin-bottom-xl padding-bottom" />
            </template>
            <template v-if="loading">
              <Skeleton class="margin-bottom" width="100%" height="64px" border-radius="16px" />
              <Skeleton width="100%" height="300px" border-radius="16px" />
            </template>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div
          class="segment padding-top-zero"
          :class="!alreadySigned ? 'checkbox-container' : 'padding-bottom-zero'"
        >
          <div class="segment-content">
            <Checkbox
              v-if="!alreadySigned"
              class="content-title"
              :text="$translations.contracts.terms"
              type="fullcheck"
              :checked="checked"
              @value="checked = !checked"
            />
          </div>
        </div>
      </template>
    </PageContainer>
    <template v-else>
      <SuccessSign :close="close" />
    </template>
  </div>
</template>

<script>
import {
  PageContainer,
  UsrContracts,
  Skeleton,
  Checkbox,
  HtmlViewer
} from '@seliaco/red-panda';
import SuccessSign from '@/views/contracts/components/SuccessSign.vue';

export default {
  components: {
    SuccessSign,
    PageContainer,
    Skeleton,
    Checkbox,
    HtmlViewer
  },
  data () {
    return {
      loading: false,
      contract: null,
      alreadySigned: true,
      showBanner: false,
      checked: false,
      close: {
        icon: 'icon-close',
        callback: () => {
          this.$router.push({ name: 'Home' })
        }
      }
    }
  },
  mounted () {
    this.loading = true
    UsrContracts.getContract(this.$route.params.specialistId)
      .then((body) => {
        this.contract = body.contract
        this.alreadySigned = body.signed_contract_at
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    sign () {
      UsrContracts.signInformedConsent(this.contract.id, this.$route.params.specialistId)
        .then(() => this.showBanner = true)
        .catch((e) =>
          this.$toast({
            severity: 'error',
            text: e.message
          })
        )
    }
  },
  computed: {
    submit () {
      let submit = {
        disabled: true
      }

      if (this.checked) {
        submit.disabled = false
      }

      if (!this.alreadySigned) {
        submit = {
          ...submit,
          text: this.$translations.contracts.action,
          callback: () => {
            this.sign()
          }
        }
      }

      return submit
    }
  }
}
</script>

<style lang="sass" scoped>
.not-signed
  ::v-deep .page-container-action
    border-top: 1px solid var(--gray-5) !important
.checkbox-container
  border-bottom: 1px solid var(--gray-10)
  margin-bottom: 16px
</style>
